import * as React from "react";
import { graphql, HeadFC } from "gatsby";

import Layout from "../../../components/layouts/Default/Default";
import { ResponsiveBlock } from "../../../components/layouts/ResponsiveBlock/ResponsiveBlock";

import { CommonHead } from "../../../components/commons/Head/Head";

import { KoreanDivisionAbstruct } from "../../../components/elements/DivisionAbstruct/DivisionAbstruct";
import Hr from "../../../components/elements/Hr/Hr";
import {
  KLHeader,
  KLBox,
  KLCardHeader,
  KLCard,
  KLNumberHeader,
  KLNumbers,
  KLButtons,
  KLSection1,
  KLSection2,
  KLSection3,
} from "../../../components/elements/KoreanLeaf/KoreanLeaf";
import LeftBar from "../../../components/elements/LeftBar/LeftBar";
import SnsShare from "../../../components/elements/SnsShare/SnsShare";
import Vimeo from "../../../components/elements/Vimeo/Vimeo";

export const query = graphql`
  query {
    allSettingsJson {
      nodes {
        id
        siteTitle
      }
    }
  }
`;

const IndexPage = ({ data }: any) => {
  const title = data.allSettingsJson?.nodes[0].siteTitle;
  return (
    <Layout title={title} pageType="TopPage">
      <>
        {/* メイン動画 */}
        <Vimeo
          vimeoId="944314476"
          privacyHash="d5b3e8c21c"
          autoPlay
          controls
          muted
        />
        {/* 韓国語用リーフヘッダー */}
        <KLHeader
          bgColorClass="tw-bg-[#78C375]"
          koreanTitle="배우다"
          japaneseTitle="学ぶ"
          anchorList={[
            { id: "gogaku", title: "語学" },
            { id: "shikaku", title: "資格" },
            { id: "tuuyaku", title: "通訳" },
          ]}
          imageSrc="/images/korean/kv‐learn.jpg"
        />
        <KLBox sentence="レベル別少人数の自分に合ったクラスで、２年間ネイティブから韓国語と英語を学ぶ。" />
        <Hr />
        <LeftBar>
          <ResponsiveBlock bgColor="">
            <KLCardHeader title="語学" id="gogaku" />
            <KLCard
              bgColorClass="tw-bg-[#CBF7F7]"
              imageSrc="/images/korean/3-1-learn.JPG"
              title="会話を中心にレベル別クラスでネイティブから学ぶ"
              lines={[
                "入門から上級まで細かいレベル別援業で学べる",
                "毎日ネイティブの韓国語に触れながら自然に身につく",
                "話せるだけでなく、資料作成やプレゼンテーションまで韓国語でできる力を身につける",
              ]}
            />
            <KLCardHeader title="資格" id="shikaku" />
            <KLCard
              bgColorClass="tw-bg-[#CBF7F7]"
              imageSrc="/images/korean/3-2-learn.JPG"
              title="韓国語の検定だけでなく、英検やTOEICも取得"
              lines={[
                "韓国語能力試験(TOPIK)やハングル検定の中級以上の資格取得を目指す",
                "TOPIKは問題対策だけでなく、作文対策も授業で実施",
                "英検・TOEICは授業で対策、学内受験で資格取得を目指す",
              ]}
            />
            <KLCardHeader title="通訳" id="tuuyaku" />
            <KLCard
              bgColorClass="tw-bg-[#CBF7F7]"
              imageSrc="/images/korean/3-3-learn.JPG"
              title="通訳教室で生教材を使って授業"
              lines={[
                "通訳の練習ができる専門の教室で通訳の授業を受けることができる",
                "教材はインタビューやドラマ、バラエティーなどの生教材を使用",
                "すべてのクラスが通訳の授業を受講できる",
              ]}
            />
          </ResponsiveBlock>
        </LeftBar>
        <Hr />
        <ResponsiveBlock bgColor="">
          <KLSection1 />
        </ResponsiveBlock>
        <Hr />
        <ResponsiveBlock bgColor="">
          <KLNumberHeader title="数字で見る" id="suujidemiru" />
          <KLNumbers
            items={[
              {
                title: "レベル",
                titleSmaller: "",
                subtitle: "",
                unitFront: "",
                unitSide: "",
                unitSideSmaller: false,
                threeDigits: false,
                number: "10",
                unitBack: "レベル",
                description:
                  "入門から上級レベルまで細かいレベル分けを実現。自分に合ったクラスで勉強できます。",
              },
              {
                title: "韓国語",
                titleSmaller: "",
                subtitle: "",
                unitFront: "",
                unitSide: "週",
                unitSideSmaller: false,
                threeDigits: false,
                number: "7",
                unitBack: "コマ",
                description:
                  "毎日韓国語に触れることで韓国語のレベルアップを目指します。授業はほぼ韓国語で行われます。",
              },
              {
                title: "英 語",
                titleSmaller: "",
                subtitle: "",
                unitFront: "",
                unitSide: "週",
                unitSideSmaller: false,
                threeDigits: false,
                number: "4",
                unitBack: "コマ",
                description:
                  "日本人と英語圏のネイティブの先生がダブルで指導するので、安心して英語が学べます。",
              },
            ]}
          />
          <KLNumbers
            items={[
              {
                title: "TOPIK",
                titleSmaller: "",
                subtitle: "中高級",
                unitFront: "",
                unitSide: "",
                unitSideSmaller: false,
                threeDigits: false,
                number: "50",
                unitBack: "名以上",
                description:
                  "韓国語能力試験に積極的にチャレンジします。TOPIKのプロから問題を解くコツを教えてもらうことで、確実に中高級取得を目指すことができます。",
              },
              {
                title: "英 検",
                titleSmaller: "",
                subtitle: "2級以上",
                unitFront: "",
                unitSide: "",
                unitSideSmaller: false,
                threeDigits: false,
                number: "30",
                unitBack: "名以上",
                description:
                  "英検2級以上の取得で、将来の選択肢も広がります。英語が苦手な人も、レベル別の対策授業を通して学内受験で英検取得を目指すことができます。",
              },
            ]}
          />
        </ResponsiveBlock>
        <Hr />
        <KLButtons
          buttons={[
            { name: "出会う", url: "/korean/meet/" },
            { name: "感じる", url: "/korean/feel/" },
            { name: "叶う", url: "/korean/realize/" },
          ]}
        />
        <Hr />
        <SnsShare
          path="/korean"
          title={title}
          buttonName="戻る"
          buttonUrl="/korean"
          spaceBottom
        />
        <Hr />
        <KoreanDivisionAbstruct spaceBottom />
      </>
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = ({ data }: any) => (
  <CommonHead
    ogTitle={`学ぶ - 韓国語＋英語専攻 - ${data.allSettingsJson?.nodes[0].siteTitle}`}
    ogDescription="レベル別少人数の自分に合ったクラスで、２年間ネイティブから韓国語と英語を学ぶ"
    ogImageFilepath="/images/korean/kv‐learn.jpg"
  >
    <title>
      学ぶ - 韓国語＋英語専攻 - {data.allSettingsJson?.nodes[0].siteTitle}
    </title>
  </CommonHead>
);
